<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addDevice"
                >添加虫情设备</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%; margin-top: 1vh"
    >
      <el-table-column
        prop="name"
        label="设备名称"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="deviceNumber"
        label="设备编号"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="deviceType"
        label="设备类型"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.deviceType == 6">虫情设备</span>
          <span v-if="scope.row.deviceType == 7">孢子设备</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="latestCollectionCount"
        label="最新识别数量"
        show-overflow-tooltip
        align="center"
      ></el-table-column> -->
      <el-table-column
        prop="leader"
        label="负责人"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="联系方式"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="position"
        label="所在位置"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="latitudeAndLongitude"
        label="经纬度"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <!-- <el-table-column
        prop="status"
        label="设备状态"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1" style="color: green">在线</span>
          <span v-if="scope.row.status == 0" style="color: red">离线</span>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="createTime"
        label="更新时间"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope">
<!--          <el-button @click="show(scope.row)" type="success" size="small"
            >详情</el-button
          >-->
          <el-button @click="edit(scope.row)" type="success" size="small"
            >编辑</el-button
          >
          <el-button @click="del(scope.row)" type="success" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 添加   编辑-->
    <el-dialog
      title=""
      :visible.sync="messageBox"
      width="31vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            font-size: 2.22vh;
          "
        ></span>
      </div>
      <div class="addAlaForm">
        <el-form
          ref="addForm"
          :rules="rules"
          :model="addForm"
          label-width="10vw"
        >
          <el-form-item label="设备名称" prop="name">
            <el-input
              v-model="addForm.name"
              placeholder="请输入设备名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备类型" prop="deviceType">
            <el-select
              v-model="addForm.deviceType"
              placeholder="请选择设备类型"
            >
              <el-option
                  v-for="(item, i) in deviceTypeList"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                  :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品牌" prop="brand">
            <el-select v-model="addForm.brand" placeholder="请选择设备品牌">
              <el-option label="精讯" value="0"></el-option>
              <el-option label="建大仁科" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备编号" prop="deviceNumber">
            <el-input
                disabled
                v-if="pid"
                v-model="addForm.deviceNumber"
                placeholder="请输入设备编号"
            ></el-input>
            <el-input
                v-else
                v-model="addForm.deviceNumber"
              placeholder="请输入设备编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="负责人" prop="leader">
            <el-input
              v-model="addForm.leader"
              placeholder="请输入负责人"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="phone">
            <el-input
              v-model="addForm.phone"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item label="所在位置" prop="position">
            <el-input
              v-model="addForm.position"
              placeholder="请输入所在位置"
            ></el-input>
          </el-form-item>
          <el-form-item label="经纬度" prop="latitudeAndLongitude">
            <el-input
              v-model="addForm.latitudeAndLongitude"
              placeholder="请输入经纬度"
            ></el-input>
          </el-form-item>
          <set-Map
            id="mapPosition"
            ref="mapPosition"
            @getDatas="getDatas"
          ></set-Map>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
          v-show="showFlag"
          style="margin-top: 2vh"
        >
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!--    详情-->
    <el-drawer
      title="详情"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      class="drawers"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        ></span>
      </div>
      <div class="addAlaForm">
        <div class="msg">
          <span>设备名称：</span><span>{{ this.msgs.name }}</span>
        </div>
        <div class="msg">
          <span>设备编号：</span><span>{{ this.msgs.deviceNumber }}</span>
        </div>
        <div class="msg">
          <span>最新识别数量</span
          ><span>{{ this.msgs.latestCollectionCount }}</span>
        </div>
        <div class="msg">
          <span>负责人：</span><span>{{ this.msgs.leader }}</span>
        </div>
        <div class="msg">
          <span>联系方式：</span><span>{{ this.msgs.phone }}</span>
        </div>
        <div class="msg">
          <span>所在位置：</span><span>{{ this.msgs.position }}</span>
        </div>
        <div class="msg">
          <span>经纬度：</span><span>{{ this.msgs.latitudeAndLongitude }}</span>
        </div>
        <!-- <div class="msg">
          <span>设备状态：</span>
          <span v-if="this.msgs.status == 1" style="color: #01b70e">在线</span>
          <span v-if="this.msgs.status == 0" style="color: red">离线</span>
        </div> -->
        <div class="msg">
          <span>更新时间：</span>
          <span>{{ this.msgs.createTime }}</span>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import setMap from "@/components/setMap";

export default {
  components: {
    setMap,
  },
  data() {
    const checkPhone = (rule, value, callback) => {
      const phoneMsg = /^[1][3|5|7|8|9]\d{9}$/;
      if (!value) {
        return callback(new Error("联系方式不可为空"));
      }
      setTimeout(() => {
        if (phoneMsg.test(value)) {
          callback();
        } else {
          callback(new Error("联系方式格式不正确"));
        }
      }, 100);
    };

    return {
      deviceTypeList: [
        { id: "6", name: "虫情设备" },
        { id: "7", name: "孢子设备" },
      ],
      value1: "",
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      ids: 0,
      searchName: "",
      direction: "rtl",
      drawer: false,
      formTitle: "",
      addForm: {
        name: "",
        deviceNumber: "",
        deviceType: 0,
        leader: "",
        position: "",
        phone: "",
        uploadInterval: "",
        latitudeAndLongitude: "",
        rain: 0,
        wind: 0,
        startType: "position",
        brand: ""

      },
      addForms: { latitudeAndLongitude: "" },
      rules: {
        name: [
          {
            required: true,
            message: "设备名称不可为空",
            trigger: ["blur", "change"],
          },
        ],
        deviceType: [
          {
            required: true,
            message: "设备类型不可为空",
            trigger: ["blur", "change"],
          },
        ],
        deviceNumber: [
          {
            required: true,
            message: "设备编号不可为空",
            trigger: ["blur", "change"],
          },
        ],
        leader: [
          {
            required: true,
            message: "负责人不可为空",
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            validator: checkPhone,
            trigger: ["blur", "change"],
          },
        ],
        position: [
          {
            required: true,
            message: "所在位置不可为空",
            trigger: ["blur", "change"],
          },
        ],
        latitudeAndLongitude: [
          {
            required: true,
            message: "经纬度不可为空",
            trigger: ["blur", "change"],
          },
        ],
        rain: [
          {
            required: true,
            message: "是否开启雨天采集不可为空",
            trigger: ["blur", "change"],
          },
        ],
        wind: [
          {
            required: true,
            message: "是否开启风天采集不可为空",
            trigger: ["blur", "change"],
          },
        ],
        startType: [
          {
            required: true,
            message: "开启形式不可为空",
            trigger: ["blur", "change"],
          },
        ],
        // uploadInterval: [
        //   {
        //     required: true,
        //     message: "上传间隔不可为空",
        //     trigger: ["blur", "change"],
        //   },
        // ],
      },

      rainList: [
        { id: 0, name: "否" },
        { id: 1, name: "是" },
      ],
      windList: [
        { id: 0, name: "否" },
        { id: 1, name: "是" },
      ],
      startTypeList: [{ id: "position", name: "自动开启" }],
      messageBox: false,
      showFlag: false,
      value: true,
      msgs: [],
      center1: [],
      pid: "",
      userName: "",
      token: "e088308ff8124139a694a2934bbf6667",
      password: "4c5aeec3809645e95d720a0c0298d880",
    };
  },
  mounted() {
    this.getList();
    if (this.$store.state.user) {
      this.userName = this.$store.state.user;
    }
  },
  methods: {
    // 重置
    update() {
      this.searchName = "";
      this.getList();
    },
    //请求列表数据
    getList() {
      // this.$get("/insectPestStation/listInsectPestStations", {
      this.$get("/insectManage/page", {
        page: this.page,
        size: this.size,
        // search: this.searchName,
      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.datas;
          // this.deviceData = res.data.datas;
          // this.total = res.data.count;
          //console.log("获取列表", this.tableData);
        }
      });
    },
    // 确认提交
    saveForm(formName) {
      let id;
      if (this.formTitle == "添加虫情设备") {
        id = 0;
      } else if (this.formTitle == "编辑虫情设备") {
        id = this.ids;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$postJSON("/insectManage/save", {
            id: id,
            rain:this.addForm.rain,
            wind:this.addForm.wind,
            startType:this.addForm.startType,
            name:this.addForm.name,
            leader: this.addForm.leader,
            deviceNumber: this.addForm.deviceNumber,
            phone:this.addForm.phone,
            position: this.addForm.position,
            latitudeAndLongitude: this.addForm.latitudeAndLongitude,
            uploadInterval: this.addForm.uploadInterval,
            hours: 0,
            minute: 0,
            deviceType: this.addForm.deviceType,
            brand: this.addForm.brand
          }).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("操作成功");
              this.messageBox = false;
              this.getList();
            } else {
              this.$message.error(res.data.msg);
              this.messageBox = false;
            }
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    // 新增
    addDevice() {
      this.formTitle = "添加虫情设备";
      this.messageBox = true;
      this.showFlag = true;
      this.pid = "";
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.addForm.resetFields();
        this.$refs.mapPosition.setMap1(119.685677, 31.344034);
      });
    },
    //编辑
    edit(item) {
      //console.log("编辑", item);
      this.ids = item.id;
      this.pid = item.id;
      this.showFlag = true;
      this.formTitle = "编辑虫情设备";
      this.messageBox = true;
      this.$nextTick(() => {
        this.addForm = JSON.parse(JSON.stringify(item));
        let arr = item.latitudeAndLongitude.split(",");
        this.$refs.mapPosition.setMap1(arr[0], arr[1]);
      });
      this.data = item;
    },
    //删除
    del(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/insectManage/delete/${item.id}`).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.info(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取地图组件数据
    getDatas(e) {
      this.addForm.latitudeAndLongitude = e;
      this.addForm.longitude = e.split(",")[0];
      this.addForm.latitude = e.split(",")[1];
      this.$forceUpdate();
    },
    // 搜索
    search() {
      // this.page = 1
      this.getList();
    },
    handleClose() {
      this.drawer = false;
    },
    handleCloses() {
      this.messageBox = false;
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    // 详情
    show(item) {
      this.ids = item.id;
      this.formTitle = "虫情监测数据";
      this.drawer = true;
      this.showData = this.tableData;
      for (let i = 0; i < this.showData.length; i++) {
        if (item.id == this.showData[i].id) {
          this.msgs = this.showData[i];
          //console.log(this.msgs);
        }
      }
    },
    // marker点击
    positionObtain(val) {
      //console.log("经纬度", val);
      let data = val;
      this.addForm.latitudeAndLongitude = data;
      //console.log("11", this.addForm.latitudeAndLongitude);
    },
    searchMap() {
      let _this = this;
      AMap.service(["AMap.PlaceSearch"], function () {
        var address = _this.locationsss;
        //构造地点查询类
        var placeSearch = new AMap.PlaceSearch({
          pageSize: 5, // 单页显示结果条数
          pageIndex: 1, // 页码
          //city: "010",
          citylimit: true, //是否强制限制在设置的城市内搜索
          map: mapTwo, // 展现结果的地图实例
          //               panel: "msg", // 结果列表将在此容器中进行展示。
          autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        });
        //关键字查询
        placeSearch.search(address);
      });
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button,
.el-button--info {
  margin-left: 10px !important;
}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}

.title /deep/ .el-form-item__content,
.el-date-editor,
.el-range-editor,
.el-input__inner,
.el-date-editor--datetimerange {
  padding: 0px 10px !important;
}

.title .upDate {
  position: absolute !important;
  right: 1vw !important;
}

.textA /deep/ .el-input__inner {
  height: 10vh;
}

.drawers /deep/ .el-drawer__header {
  margin-bottom: 4px !important;
}

.msg {
  padding: 1vh 2vw;
  font-size: 1vh;
  width: 88%;
}

.pickerBox {
  width: 90%;
  margin-left: 2vw;
}

.pickerBox /deep/ .el-input__inner {
  width: 100% !important;
  height: 30px !important;
}

.pickerBox /deep/ .el-range-separator,
.pickerBox /deep/ .el-input__icon .el-range__icon .el-icon-time {
  line-height: 30px !important;
}

.msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.addAlameBox /deep/ .el-form-item__label {
  width: 8vw !important;
}
.addAlameBox /deep/ .el-form-item__content {
  margin-left: 8vw !important;
}
</style>
